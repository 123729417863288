import React from "react";
class amenities extends React.Component {
    render() {
      return (
        
<div className="">
<div className="container-fluid py-5 w-75 h-75">
<div className="container py-5 rounded polaroid">
  <div className="row">
 
    <div className="col-lg-5">
      
      
     
      <img className="img-fluid rounded border2" src="img/15.jpg" alt="" />
     
    </div>
    <div className="col-lg-7">
  <h1 className="mb-4 text-center">
  7 Star Amenities
      </h1>
      <img className="img-fluid rounded" src="img/16.png" alt="" />

    </div>
   
  </div>
</div>
</div>


</div>


);
}
}

export default amenities;