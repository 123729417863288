import React from "react";
import './fourbed_gallery.css'

class fourbed_gallery extends React.Component {


  render() {
    return (
        <div className="">
 
  </div>
);
}
}

export default fourbed_gallery;