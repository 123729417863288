import React from "react";

import { Link } from 'react-router-dom'
class projects_multiple extends React.Component {
  render() {
    return (
 <div>

 <div className="container-fluid py-5 w-75 h-75">
 <div className="container py-5 polaroid">
   <div className="row">
     <div className="col-lg-5">
       <img className="img-fluid rounded border2" src="img/8.jpg" alt="" />
     </div>
     <div className="col-lg-7 mt-4 mt-lg-0 text-center">
       <h2
         className="position-relative text-center bg-white text-primary rounded p-3 mt-4 mb-4 d-none d-lg-block"
         style={{ width: 350, marginLeft: "-205px" }}
       >
         25 Years Experience
       </h2>
       <h6 className="text-uppercase">Learn About Us</h6>
       <h1 className="mb-4">
       Shaping Pakistan’s
Luxury Real Estate Market
FOR A HIGHER LIVING STANDARD
       </h1>
       <p>
       Since 2020, City Realtors Properties has shaped the luxury real estate industry in Pakistan by constructing iconic luxury residences, apartments, businesses, and commercial facilities in the region and beyond. With a vast and diversified portfolio that includes top-notch luxury master-planned developments in different cities, H&S enhances the liveliness of the communities where its projects are located.
       </p>
       
       <Link className="btn btn-primary mt-2" to='/Proj1_details'>Project Details</Link>
                
     </div>

<br/>
<div className="container"> <hr className="solid"></hr></div>
<br/>
    

     <div className="col-lg-7 mt-4 mt-lg-0 text-center">
      
      <h1 className="mb-4 ">
      The COLLECTION of Luxury Signature
      </h1>
      <p>
      At City Realtors, The Signature Collection offers the most opulent Residences and will provide the pinnacle of luxury living. Each property has a prime location, breathtaking private terraces in the sky, and spectacular views. Additionally, several of the Luxury Signature Residences include magnificent private and clean pools. Warm and welcoming interiors provide the ideal location to call home. A new standard of luxury will be established in Lahore by The Signature Collection.</p>
      
      <Link className="btn btn-primary mt-2" to='/Proj2_details'>Project Details</Link>
    </div>
   
    <div className="col-lg-5">
      <img className="img-fluid rounded border2" src="img/14.jpg" alt="" />

    </div>
   </div>
 </div>
</div>




</div>
);
}
}

export default projects_multiple;