import React from "react";

const Blogs = ({ blogs }) => {
  const siteURL = "https://mogulbeat.com/MogulBeatBackend/API/";
  return (
    <section id="from-blog">
      <div className="container-fluid" style={{ maxWidth: "1300px" }}>
        <div className="from-blog-area">
          <div className="title-area">
            <h2 className="section-heading text-center">Latest Articles</h2>
            <span className="tittle-line" />
            <p />
          </div>
          {/* From Blog content */}

          <div className="row mt-5 mb-3">
            {blogs.map((v, i) => (
              <div
                key={"svsdaf" + i}
                className="p-2 col-xl-3 col-lg-4 col-sm-6"
              >
                <a
                  href={"/blogDetail/" + v.id}
                  key={"sd" + i}
                  className="card  h-100"
                >
                  <div>
                    <img
                      style={{ height: "200px", objectFit: "cover" }}
                      className="card-img-top"
                      src={siteURL + "/" + v.featureImage}
                      alt="Card image cap"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      <a href={"/blogDetail/" + v.id}>{v.title}</a>
                    </h5>
                    <p className="card-text mb-0">
                      {v.description.substring(0, 110) + "..."}
                    </p>
                    <p>
                      {" "}
                      <small className="text-muted">
                        {new Date(v.createdAt).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </small>
                    </p>
                    <p className="card-text">
                      <a
                        href={"/blogDetail/" + v.id}
                        className="read-more text-dark font-weight-bold"
                      >
                        READ MORE
                      </a>
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>

        <br />
        <div className="col-md-6 col-lg-2 ml-auto d-flex align-items-center mt-4 mt-md-0 position-relative">
          {" "}
          <a className="text-dark font-weight-bold " href="/blog">
            <i className="fa fa-angle-double-right pt-1 px-3" />
            Next
          </a>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
