import React from "react";
import './sticky2.css'
import { Link } from 'react-router-dom'

class stickyright extends React.Component {
  render() {
    return (
 <div>
 <div className="sticky-container ">
  <ul className="sticky">
  
   

  
    <li>
      <img src="/img/ph.png" width={40} height={40} className=""/>
      <a><span> </span></a>
      <p>
      <Link className=" " to='/contactus'>
       Contact US
  
        </Link>
      </p>
    </li>
   
  </ul>
</div>

</div>
);
}
}

export default stickyright;