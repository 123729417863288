import React from "react";

class abt_info extends React.Component {
  render() {
    return (
      <div>
        {/* Action Start */}
        <div className="container mt-4 mb-3">
          <div className="section-header text-center">
            <h1 className="section-heading">INSPIRATION</h1>
            <h4 className="ff-chronicle">Our INSPIRATION</h4>
          </div>
        </div>
        {/* Action End */}
      </div>
    );
  }
}

export default abt_info;
