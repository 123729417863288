import React from "react";

class grid extends React.Component {
  render() {
    return (
      <div className="container ">
        <div className="row">
          <div className="col">
            <h2 className="ff-chronicle">Thinkers Conferences</h2>
            <br />
            <p>
              Welcome to MOGULBEAT, your dedicated platform for personal growth,
              productivity, personality development, interview, and career
              advice. I'm Kashif Riaz, and with over 22 years of experience in
              the corporate world, I am passionate about helping individuals
              unlock their true potential and achieve success in all aspects of
              life.
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-lg-6">
            <br />
            <br />
            <br />
            <h2 className="ff-chronicle">Who are the Mogulbeat?</h2>
            <hr className="solid"></hr>
            <br />
            <p>
              At MOGULBEAT, we aim to provide valuable resources and insights to
              empower you on your journey toward personal growth and
              professional success. Whether you're looking for guidance on
              enhancing your productivity, developing your leadership skills,
              staying motivated, or nurturing your entrepreneurial spirit, we
              have you covered.
            </p>
            {/*<a className="text-dark font-weight-bold"href=""><i className="fa fa-angle-double-right pt-1 px-3" />Read more</a>*/}
          </div>

          <div className="col-lg-6">
            <img
              className="img-fluid rounded"
              src="img/Mask_Group_26.png"
              alt=""
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-lg-6">
            <img
              className="img-fluid rounded"
              src="img/Mask_Group_27.png"
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <br />
            <br />
            <br />
            <h2 className="ff-chronicle">Thinkers Conferences</h2>
            <hr className="solid"></hr>
            <br />
            <p>
              Through our carefully curated articles, you'll discover practical
              tips, strategies, and inspiration to fuel your personal and
              professional development. We also understand the significance of
              excelling in job interviews, and our specialized articles offer
              valuable insights and advice to help job seekers stand out from
              the competition and secure their dream positions.
            </p>
            {/*<a className="text-dark font-weight-bold"href=""><i className="fa fa-angle-double-right pt-1 px-3" />Read more</a>*/}
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-lg-6">
            {" "}
            <br />
            <br />
            <br />
            <h2 className="ff-chronicle">We also love to write articles</h2>
            <hr className="solid"></hr>
            <br />
            <p>
              Together, let's embark on a transformative journey toward
              unlocking your full potential and achieving the success you
              deserve.
            </p>
            {/*<a className="text-dark font-weight-bold"href=""><i className="fa fa-angle-double-right pt-1 px-3" />Read more</a>*/}
          </div>

          <div className="col-lg-6">
            <img
              className="img-fluid rounded"
              src="img/Rectangle_119.png"
              alt=""
            />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default grid;
