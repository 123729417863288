import React from "react";

class corporate_info extends React.Component {
  render() {
    return (
      <div>
        {/* Action Start */}
        <div className="container  ">
          <div className="section-header text-center">
            <h1 className="section-heading">CORPORATE</h1>
            <h4 className="ff-chronicle">MEET OUR CORPORATES</h4>
          </div>
        </div>
        {/* Action End */}
      </div>
    );
  }
}

export default corporate_info;
