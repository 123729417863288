import React from "react";

class fourbed_header extends React.Component {
  render() {
    return (
      <div className="container rounded w-75 h-75 ">
  {/* Page Header Start */}
  <div className="container-fluid bg-page-header" style={{ marginBottom: 40 }}>
    <div className="container">
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ minHeight: 150 }}
      >
        <h2 className="display-4 text-white text-uppercase">4Bed Duplex Signature Suites</h2>
        <div className="d-inline-flex text-white">
          <p className="m-0 text-uppercase">
            <a className="text-white" href="">
              Home
            </a>
          </p>
          <i className="fa fa-angle-double-right pt-1 px-3" />
          <p className="m-0 text-uppercase">4 Bed Duplex Signature Suites</p>
        </div>
      </div>
    </div>
  </div>
  {/* Page Header End */}
  </div>
);
}
}

export default fourbed_header;

