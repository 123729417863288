import React from "react";

import { Link } from "react-router-dom";
import axios from "axios";

class blogs extends React.Component {
  constructor() {
    const pathName = window.location.pathname;
    const slug = pathName.split("/");
    super();
    this.state = {
      siteURL: "https://mogulbeat.com/MogulBeatBackend/API/",
      error: {},
      id: "",
      author: "",
      title: "",
      description: "",
      category: "",
      featureImage: "",
      tags: "",
      data: [],
      slug: slug[2] || "",
      slug1: slug[3] || "",
    };
    this.onChange = this.onChange.bind(this);
    this.GetId = this.GetId.bind(this);
    this.handleId = this.handleId.bind(this);
  }

  componentWillMount() {
    let url =
      "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogs?isPublished=0";

    if (this.state.slug) {
      url =
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogsByCategory?category=" +
        this.state.slug;
    }

    if (this.state.slug1) {
      url =
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogsBysubCategory?subCategory=" +
        this.state.slug1;
    }
    axios
      .get(url)
      .then((res) => {
        const response = res.data;
        if (response.status) {
          //   this.setState({error:{}});
          this.setState({ data: response.data });
          // alert(response.message);
        } else {
          this.setState({ error: response.error });
          //alert(response.message);
        }
      })
      .then(() => {})
      .catch((error) => {});
  }
  componentDidMount() {}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleId = (value) => {
    this.setState({
      id: value,
    });
  };

  GetId(e) {
    e.preventDefault();

    try {
      axios
        .get(
          "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetBlogById?id=" +
            this.state.id
        )
        .then((res) => {
          console.log(res);
        });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="container-fluid rounded  py-5 ">
        <div className="section-header text-center">
          {this.state.slug ? (
            <>
              <h5 className="mb-0">Articles On </h5>
              <h1 className="text-capitalize">
                {this.state.slug.replace(/%20/g, " ")}
              </h1>
            </>
          ) : (
            <h1>Our Blogs</h1>
          )}
          <h4>Read Our Blogs</h4>
        </div>
        <div className="container">
          <div className="row">
            {this.state.data.map((v, i) => (
              <div key={"svsdaf" + i} className="p-3 col-lg-4 col-md-6">
                <div key={"sd" + i} className="card xl   h-100">
                  <div>
                    <img
                      style={{ height: "200px", objectFit: "cover" }}
                      className="card-img-top"
                      src={this.state.siteURL + "/" + v.featureImage}
                      alt="Card image cap"
                    />
                  </div>
                  <div className="card-body">
                    {" "}
                    <h5 className="card-title">
                      <a href={"/blogDetail/" + v.id}>{v.title}</a>
                    </h5>
                    <p className="card-text mb-0">
                      {v.description.substring(0, 110) + "..."}
                    </p>
                    <p>
                      {" "}
                      <small className="text-muted">
                        {new Date(v.createdAt).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </small>
                    </p>
                    <p className="card-text">
                      <a
                        href={"/blogDetail/" + v.id}
                        className="read-more text-dark font-weight-bold"
                      >
                        READ MORE
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default blogs;
