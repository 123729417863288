import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      siteURL: "https://mogulbeat.com/MogulBeatBackend/API/",
      error: {},

      blogs: [],
    };
  }

  componentWillMount() {
    axios
      .get(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogs?isPublished=0"
      )
      .then((res) => {
        const response = res.data;
        if (response.status) {
          //   this.setState({error:{}});
          const newData = [];
          for (let i in response.data) {
            newData.push(response.data[i]);

            if (i === "2") break;
          }
          this.setState({ blogs: newData });
        } else {
          this.setState({ error: response.error });
          //alert(response.message);
        }
      })
      .then(() => {})
      .catch((error) => {});
  }

  render() {
    return (
      <footer>
        {/* Footer Start */}
        <div
          className="text-white pt-1"
          style={{ marginTop: 90, backgroundColor: "#212121" }}
        >
          <div className="container  px-sm-3 px-md-5 ">
            <div className="row pt-4">
              {/* <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                <h2
                  style={{ color: "var(--color-2)" }}
                  className="main-logo mb-2"
                >
                  MOGULBEAT
                </h2>
                  FOLLOW US:
                <div className="d-flex justify-content-start mt-4">
                  <a className="social-icon twitter mr-2" href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="social-icon facebook mr-2" href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="social-icon linkedin mr-2" href="#">
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a className="social-icon  instagram" href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>  
              </div> */}
              {/* <div className="col-xl-3 col-lg-4 mr-auto col-md-6 mb-3">
                <h4 className="font-weight-semi-bold text-white mb-4">
                  Popular Links
                </h4>
                <div className="d-flex flex-column justify-content-start">

                  <div>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Growth/personal development"}
                        >
                          Personal Development
                        </a>
                      </li>
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Growth/time management"}
                        >
                          Time Management
                        </a>
                      </li>
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Growth/financial literacy"}
                        >
                          Financial Literacy
                        </a>
                      </li>
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Growth/leadership"}
                        >
                          Leadership
                        </a>
                      </li>
                    </ul>
                  </div>
 
                  <div>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Career/job interview"}
                        >
                          Job Interview
                        </a>
                      </li>
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Career/cv & resume"}
                        >
                          CV & Resume
                        </a>
                      </li>
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Career/work place challanges"}
                        >
                          Work Place Challanges
                        </a>
                      </li>
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Career/career coaching"}
                        >
                          Career Coaching
                        </a>
                      </li>
                    </ul>
                  </div>
 
                  <div>
                    <ul className="list-unstyled">
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Reading Circle/books review"}
                        >
                          Book Review
                        </a>
                      </li>

                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Reading Circle/ceo corner"}
                        >
                          CEO Corner
                        </a>
                      </li>
                      <li>
                        <a
                          className="footer-nav-link"
                          href={"/blog/Reading Circle/corporate affair"}
                        >
                          Corporate Affair
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-4 col-lg-4 mr-auto col-md-6 mb-3">
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Growth/personal development"}
                    >
                      Personal Development
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Growth/time management"}
                    >
                      Time Management
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Growth/financial literacy"}
                    >
                      Financial Literacy
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Growth/leadership"}
                    >
                      Leadership
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Career/job interview"}
                    >
                      Job Interview
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Career/cv & resume"}
                    >
                      CV & Resume
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-xl-4 col-lg-4 mr-auto col-md-6 mb-3">
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Career/work place challanges"}
                    >
                      Work Place Challanges
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Career/career coaching"}
                    >
                      Career Coaching
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Reading Circle/books review"}
                    >
                      Book Review
                    </a>
                  </li>

                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Reading Circle/ceo corner"}
                    >
                      CEO Corner
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-nav-link"
                      href={"/blog/Reading Circle/corporate affair"}
                    >
                      Corporate Affair
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 mb-3">
                <h4 className="font-weight-semi-bold mb-3">Blogs</h4>
                <div className="d-flex flex-column justify-content-start">
                  {this.state.blogs.map((v, i) => (
                    <Link
                      key={i + "asdf"}
                      className="mb-2 d-flex"
                      to={"/blogDetail/" + v.id}
                    >
                      <span className="d-block">
                        {" "}
                        <i className="fa fa-angle-right mr-2" />
                      </span>
                      <span className="d-block">{v.title}</span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderTopColor: "#444444" }} />
          <div
            className="pb-4 mx-0"
            // style={{ background: "rgba(256, 256, 256, .05)" }}
          >
            <div className="container text-center py-0">
              <div className="d-flex justify-content-center py-1">
                {/* <a className="social-icon twitter" href="#">
                  <i className="fab fa-twitter" />
                </a> */}
                <a
                  className="social-icon facebook"
                  href="https://www.facebook.com/profile.php?id=100090625310560"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  className="social-icon linkedin"
                  href="https://www.linkedin.com/in/kashif-riaz-mogulbeat/"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
                {/* <a className="social-icon  instagram" href="#">
                  <i className="fab fa-instagram" />
                </a> */}
              </div>
              <h2
                style={{ color: "var(--color-2)" }}
                className="ff-chronicle font-weight-semi-bold my-2"
              >
                MOGULBEAT
              </h2>
              <small>
                Copyright © <span>{new Date().getFullYear()} </span>
                <a
                  className="font-weight-bold "
                  href="https://www.mogulbeat.com/"
                >
                  MOGULBEAT.
                </a>{" "}
                All Rights Reserved.
              </small>
            </div>
          </div>
        </div>
        {/* Footer End */}
      </footer>
    );
  }
}

export default Footer;
