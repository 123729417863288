import React from "react";
import { Link } from 'react-router-dom'
class slider extends React.Component {
    render() {
      return (
        <div>
{/* Carousel Start */}
<div className="container container-fluid p-0 mb-5 pb-5 w-75 h-75 ">
    <div
      id="header-carousel"
      className="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        <div
          className="carousel-item position-relative active"
          style={{ height: "100vh", minHeight: 400 }}
        >
          <img
            className="position-absolute w-100 h-100"
            src="img/1.jpg"
            style={{ objectFit: "cover" }}
          />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{ maxWidth: 900 }}>
              <h4
                className="text-white text-uppercase mb-4"
                style={{ letterSpacing: 3 }}
              >
                Pakistan's First-Ever Fully Automated Luxury Duplexes & Sky Mansions in Lahore! 
              </h4>
              <h4 className="text-capitalize text-white mb-4">
              Whether you have dreamt of a 1, 2, 3, or 4-bed luxury apartment or sky mansion, Autograph will offer a dream home for you and your family. 
              </h4>
           
                
                <Link className="btn btn-primary py-3 px-5 mt-2 " to='/contact'>Get An Appointment</Link>
            
            </div>
          </div>
        </div>
        <div
          className="carousel-item position-relative"
          style={{ height: "100vh", minHeight: 400 }}
        >
          <img
            className="position-absolute w-100 h-100"
            src="img/2.jpg"
            style={{ objectFit: "cover" }}
          />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{ maxWidth: 900 }}>
              <h4
                className="text-white text-uppercase mb-4"
                style={{ letterSpacing: 3 }}
              >
                Experience a Higher Living Standard with World-Class Amenities
              </h4>
              <h3 className="display-5 text-capitalize text-white mb-4">
              Grand provides endless luxury, comfort, convenience, and the highest standards of living in a safe and exquisite environment with smart home technology.  
              </h3>
              <a className="btn btn-primary py-3 px-5 mt-2" href="#">
                Call Us Now
              </a>
            </div>
          </div>
        </div>
      </div>
      <a
        className="carousel-control-prev"
        href="#header-carousel"
        data-slide="prev"
      >
        <div className="btn btn-lg btn-secondary btn-lg-square">
          <span className="carousel-control-prev-icon" />
        </div>
      </a>
      <a
        className="carousel-control-next"
        href="#header-carousel"
        data-slide="next"
      >
        <div className="btn btn-lg btn-secondary btn-lg-square">
          <span className="carousel-control-next-icon" />
        </div>
      </a>
    </div>
  </div>
  {/* Carousel End */}
        </div>
        );
    }
  }
  
  export default slider;