import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

class grab extends React.Component {
  constructor() {
    super();
    this.state = {
      site_url: "",
      error: {},
      btnloading: false,
      full_name: "",
      email: "",
      website: "",
      comments: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitSubscriber(e) {
    e.preventDefault();

    this.setState.email = "";

    this.setState({ btnloading: true });
    axios
      .post(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/CreateSubscriber",
        {
          email: this.state.email,
        }
      )
      .then((res) => {
        const response = res.data;
        if (response.status) {
          this.setState({ error: {} });
          // alert(response.message);

          this.setState({
            email: "",
          });
        } else {
          this.setState({ error: response.error });
          //alert(response.message);
        }
      })
      .then(() => {
        this.setState({ btnloading: false });
      })
      .catch((error) => {});
  }

  render() {
    return (
      <div>
        <section className="callaction text-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="cta-text ">
                  <h2 className="text-white">
                    Get the best Mogulbeat strategies and tools delivered right
                    to your inbox!
                  </h2>
                  <br />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="text-center">
                  <a
                    href="#"
                    className="ff-sofia-pro btn btn-theme-primary btn-sm text-white"
                    data-toggle="modal"
                    data-target="#smallModal"
                  >
                    SUBSCRIBE TO OUR NEWSLETTER
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="smallModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered ">
            <div className="container modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Subscribe Now
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Your Email"
                      value={this.setState.email}
                      onChange={this.onChange}
                      name="email"
                    />
                  </div>

                  <button
                    disabled={this.state.btnloading}
                    onClick={this.submitSubscriber.bind(this)}
                    className="btn btn-primary float-right"
                    type="submit"
                    id="sendMessageButton"
                    data-dismiss="modal"
                  >
                    {this.state.btnloading ? "loading..." : " Subscribe"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default grab;
