import React, { Component } from "react";

import "../index.js";

import Banner from "./About/abt_header";
import Info from "./About/abt_info.js";

import Grid_mult from "./About/grid_mult.js";
import About from "./about";
import Appointments from "./appointments";
import Features from "./features";
import Clients from "./clients2";

const Aboutpage = () => (
  <div className="mt-4">
    <Info />
    <Grid_mult />

    <Clients />
  </div>
);

export default Aboutpage;
