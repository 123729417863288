import React, { Component } from "react";

import "../index.js";

import Info from "./Corporate/corporate_info.js";
import Co_grid from "./Corporate/co_grid.js";
import Grid1 from "./Corporate/grid1.js";
import Grid2 from "./Corporate/grid2.js";
import News_article from "./Corporate/newsarticle.js";
import Actions from "./actions2";

const Ceopage = () => (
  <div className="mt-4">
    <Info />
    <Co_grid />
    <Grid1 />

    <News_article />
    <Grid2 />
    <Actions />
  </div>
);

export default Ceopage;
