import React from "react";
import "./imggrid.css";

class img_grid extends React.Component {
  render() {
    return (
      <div>
        <div className="container ">
          <div className="image-grid">
            <div className="image01 ">
              <div
                style={{ backgroundColor: "#00000080" }}
                className="overlay-text text-white d-flex flex-column justify-content-center px-3 w-100 text-center"
              >
                <h3 className="text-white">Welcome to our Blog</h3>
                <p>We are committed to uploading educative content daily.</p>
              </div>
            </div>
            <div className="image02" />
            <div className="image03" />
            <div className="image04" />
            <div className="image05" />
            <div className="image06" />
            <div className="image07" />
            <div className="image08" />
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default img_grid;
