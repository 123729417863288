import React from "react";
import "./proj1.css"



class proj1 extends React.Component {
  render() {
    return (
 <div className="container rounded   py-5 polaroid w-75 h-75">

<br/>

 
  <div className="row">
    <div className="col-sm "><ul className="nav nav-tabs" id="myTab" role="tablist">
    <li className="nav-item ">
      <a
        className="nav-link active "
        data-toggle="tab"
        href="#home"
        role="tab"
        aria-controls="home"
      >
        Room Details
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-toggle="tab"
        href="#profile"
        role="tab"
        aria-controls="profile"
      >Gallery
      </a>
    </li>
   
  
  
  </ul></div>
    <div className="col-10"><div className="tab-content  ">
    <div className="tab-pane active" id="home" role="tabpanel">
    <h2>Room Details</h2>
  <p>The large 5BHK sky mansion spans 5928.32 square feet across three stories, with modern luxury facilities. A premium luxury lifestyle is complemented by its cutting-edge architecture and interior décor. The captivating interior is nicely finished. The enormous original wood front doors, the soaring staircase, and the private elevator in the foyer linking each story are some of the attractions of the 5-Bed Sky Mansions. It includes a separate terrace with opulent furnishings and five bedrooms with en-suite bathrooms.</p>
      <a href="#" className="btn btn-primary btn-space">
            Book Now
          </a>
          <hr className="solid"></hr>
          <h2>Amenities</h2>
          <div className="row">
    <div className="col"><p>• Barbeque Area<br/>
    •Dedicated elevators<br/>
    •Home Theatre
   
    </p></div>
    <div className="col"> <p>•Private Balcony<br/>
    •Private Steam<br/>
    •Private Swimming Pool</p></div>
    <div className="col"><p>•State-of-the-art kitchen<br/>
    •Three stories</p></div>
    <br/>
    <br/>
    
  </div>
    </div>
    <div className="tab-pane" id="profile" role="tabpanel">
    <div className="row">
   
    <div className=""><h4>From Our Gallery</h4></div>
  </div>
    </div>
    {/* <div className="tab-pane" id="messages" role="tabpanel">
      .3..
    </div>
    <div className="tab-pane" id="settings" role="tabpanel">
      .4..
    </div> */}
  </div></div>
  </div>





  </div>
);
}
}

export default proj1;




