import React from "react";

class abt_info extends React.Component {
  render() {
    return (
      <div>
        {/* Action Start */}
        <div className="container ">
          <div className="section-header text-center">
            <h1 className="section-heading">
              LEARN MORE
              <br />
              ABOUT US
            </h1>
            <h4 className="ff-chronicle">
              Our community, experiences and articles
            </h4>
          </div>
          <div className="  py-5">
            <div className="abt_bg-action rounded2" style={{ height: 400 }}>
              <div className="row h-100 align-items-center justify-content-center">
                <div className="col-lg-7 text-center">
                  <h2 className="text-white display-2 main-logo mb-4">
                    MOGULBEAT
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Action End */}
      </div>
    );
  }
}

export default abt_info;
