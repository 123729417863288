import React from "react";
//import logo from '../img/logo-2.png'
import { Link } from "react-router-dom";
function refreshPage() {
  window.location.reload();
}

class grid2 extends React.Component {
  render() {
    return (
      <div className="container ">
        <div className="">
          <div className="row">
            <div className="col-lg-6 py-5 rounded  ">
              <p>Apr 4, 2023</p>
              <h1 className="py-2 ff-chronicle">WALMART</h1>
              {/*Card*/}
              <div className="card ">
                {/*Card image*/}
                <img
                  className="img-fluid border2 "
                  src="img/Mask_Group_35.png"
                  alt="Card image cap"
                />
                {/*Card content*/}
                <div className="card-body">
                  {/*Title*/}
                  <h4 className="card-title">Card title</h4>
                  {/*Text*/}
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <a href="#" className="btn btn-primary ff-chronicle">
                    Button
                  </a>
                </div>
              </div>
              {/*/.Card*/}
            </div>
            <div className="col-lg-6 py-5 rounded   ">
              <p>Apr 4, 2023</p>
              <h1 className="py-2 ff-chronicle">TESLA</h1>
              {/*Card*/}
              <div className="card">
                {/*Card image*/}
                <img
                  className="img-fluid  border2"
                  src="img/Mask_Group_34.png"
                  alt="Card image cap"
                />
                {/*Card content*/}
                <div className="card-body">
                  {/*Title*/}
                  <h4 className="card-title">Card title</h4>
                  {/*Text*/}
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <a href="#" className="btn btn-primary ff-chronicle">
                    Button
                  </a>
                </div>
              </div>
              {/*/.Card*/}
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default grid2;
