import React from "react";

class about extends React.Component {
  render() {
    return (
 <div className="">
 {/* About Start */}
 <div className="container-fluid py-5 w-75 h-75">
 <div className="container rounded py-5 polaroid">
   <div className="row">
     <div className="col-lg-5">
       <img className="img-fluid rounded border2" src="img/8.jpg" alt="" />
     </div>
     <div className="col-lg-7 mt-4 mt-lg-0 text-center">
       <h2
         className="position-relative text-center bg-white text-primary rounded p-3 mt-4 mb-4 d-none d-lg-block"
         style={{ width: 350, marginLeft: "-205px" }}
       >
         25 Years Experience
       </h2>
       <h6 className="text-uppercase">Learn About Us</h6>
       <h1 className="mb-4">
       Shaping Pakistan’s
Luxury Real Estate Market
FOR A HIGHER LIVING STANDARD
       </h1>
       <p>
       Since 2020, H&S Properties has shaped the luxury real estate industry in Pakistan by constructing iconic luxury residences, apartments, businesses, and commercial facilities in the region and beyond. With a vast and diversified portfolio that includes top-notch luxury master-planned developments in different cities, H&S enhances the liveliness of the communities where its projects are located.
       </p>
       <a href="" className="btn btn-primary mt-2">
         Learn More
       </a>
     </div>
   </div>
 </div>
</div>
{/* About End */}
</div>
);
}
}

export default about;