import React from "react";

class news_article extends React.Component {
  render() {
    return (
      <div>
        {/* Action Start */}
        <div className="container mt-4">
          <div className="section-header text-center">
            <h1 className="section-heading">FRESH NEWS & ARTICLES</h1>
          </div>

          <div>
            <br />
            <br />
            <br />
            <div className="row ">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded"
                  src="img/Mask_Group_33.png"
                  alt=""
                />
              </div>
              <div className="col">
                <br />
                <br />
                <br />
                <h2 className="ff-chronicle">AMAZON</h2>
                <hr className="solid"></hr>
                <br />
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>{" "}
                <a className="text-dark font-weight-bold" href="">
                  <i className="fa fa-angle-double-right pt-1 px-3" />
                  Read more
                </a>
              </div>
            </div>

            <br />
            <br />
            <br />
          </div>
        </div>
        {/* Action End */}
      </div>
    );
  }
}

export default news_article;
