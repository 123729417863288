import React from "react";
import axios from "axios";

class ceo_grid extends React.Component {
  constructor() {
    super();
    this.state = {
      siteURL: "https://mogulbeat.com/MogulBeatBackend/API/",
      error: {},
      full_name: "",
      description: "",
      profileImage: "",
      data: [],
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    axios
      .get(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllCeoWarrior?isActived=0&category=CEO"
      )
      .then((res) => {
        const response = res.data;
        if (response.status) {
          //   this.setState({error:{}});
          this.setState({ data: response.data });
          // alert(response.message);
          console.log(response.message);
        } else {
          this.setState({ error: response.error });
          //alert(response.message);
        }
      })
      .then(() => {})
      .catch((error) => {});
  }
  componentDidMount() {}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div className="container ">
        <br />
        <br />
        <br />
        {this.state.data.map((item, row) => (
          <div key={item.id}>
            {row % 2 == 0 ? (
              <div className="row">
                <div className="col-lg-6">
                  <br />
                  <br />
                  <br />
                  <h2 className="ff-chronicle">{item.full_name}</h2>
                  <hr className="solid"></hr>
                  <br />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et
                  </p>
                  {/*    <a className="text-dark font-weight-bold"href=""><i className="fa fa-angle-double-right pt-1 px-3" />More info</a>*/}
                </div>

                <div className="col-lg-6">
                  <img
                    className="img-fluid w-100 rounded"
                    src={this.state.siteURL + "/" + item.profileImage}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <img
                    className="img-fluid w-100 rounded"
                    src={this.state.siteURL + "/" + item.profileImage}
                    alt=""
                  />
                </div>
                <div className="col-lg-6">
                  <br />
                  <br />
                  <br />
                  <h2 className="ff-chronicle">{item.full_name}</h2>
                  <hr className="solid"></hr>
                  <br />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et
                  </p>
                  {/*<a className="text-dark font-weight-bold"href=""><i className="fa fa-angle-double-right pt-1 px-3" />More info</a>*/}
                </div>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        ))}

        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default ceo_grid;
