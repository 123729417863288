import React, { Component } from 'react';

import "../index.js";

//import Info from './Contact/contact_form3.js'
import Info from './Contact/test.js'
import Chead from './Contact/contacthead.js'



const Contactpage = () => (
  <div >
     <Chead />
       <Info />
      
       

       
       

</div>
)

export default Contactpage
