import React from "react";

class contacthead extends React.Component {
  render() {
    return (
        <div>
{/* Action Start */}
<div className="container-fluid ">
  
<div className="section-header text-center">
      <h1 >Contact Us</h1>
      <h4>Contact Us For Details</h4>
    </div> 

</div>
{/* Action End */}
</div>
);
}
}

export default contacthead;