import React from "react";
//import logo from '../img/logo-2.png'
import { Link } from "react-router-dom";
import axios from "axios";
function refreshPage() {
  window.location.reload();
}

class header extends React.Component {
  constructor() {
    super();
    this.state = {
      site_url: "",
      error: {},
      btnloading: false,
      full_name: "",
      email: "",
      website: "",
      comments: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitSubscriber(e) {
    e.preventDefault();

    this.setState.email = "";

    this.setState({ btnloading: true });
    axios
      .post(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/CreateSubscriber",
        {
          email: this.state.email,
        }
      )
      .then((res) => {
        const response = res.data;
        if (response.status) {
          this.setState({ error: {} });
          // alert(response.message);

          this.setState({
            email: "",
          });
        } else {
          this.setState({ error: response.error });
          //alert(response.message);
        }
      })
      .then(() => {
        this.setState({ btnloading: false });
      })
      .catch((error) => {});
  }

  render() {
    return (
      <>
        <div style={{ position: "sticky", top: "0", zIndex: "1000" }}>
          <div className="collapse" id="collapseExample">
            <div className="bg-dark p-4">
              <form className="form-group ">
                <div className="input-group ">
                  <div className="input-group-prepend ">
                    <span className="input-group-text" id="basic-addon1">
                      <span
                        className="fas fa-search"
                        data-toggle=""
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls=""
                      ></span>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control bg-dark text-white"
                    placeholder="search"
                  />
                  <div className="input-group-prepend ">
                    <button
                      type="button"
                      className="close bg-white"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <nav className="navbar   navbar-expand-xl py-2 navbar-light bg-light">
            <div className="container ">
              <button
                className="navbar-toggler order-xl-4 "
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <Link
                to="/"
                className="navbar-brand  d-flex align-items-center justify-content-center"
              >
                <h4 className="m-0 display-7 main-logo text-uppercase">
                  mogulbeat
                </h4>
              </Link>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className=" pr-4 nav-link custom active"
                      to="/"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className=" pr-4 nav-link custom"
                      to="/About"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      About
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className=" pr-4 nav-link custom dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      id="navbarDropdown"
                    >
                      Growth
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Growth/personal development"}
                        >
                          Personal Development
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Growth/time management"}
                        >
                          Time Management
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Growth/financial literacy"}
                        >
                          Financial Literacy
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Growth/leadership"}
                        >
                          Leadership
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className=" pr-4 nav-link custom dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      id="navbarDropdown"
                    >
                      Career
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Career/job interview"}
                        >
                          Job Interview
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Career/cv & resume"}
                        >
                          CV & Resume
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Career/work place challanges"}
                        >
                          Work Place Challanges
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Career/career coaching"}
                        >
                          Career Coaching
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className=" pr-4 nav-link custom dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      id="navbarDropdown"
                    >
                      Reading Circle
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Reading Circle/books review"}
                        >
                          Books Review
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Reading Circle/ceo corner"}
                        >
                          CEO Corner
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={"/blog/Reading Circle/corporate affair"}
                        >
                          CORPORATE AFFAIR
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="d-flex align-items-center">
                  <Link
                    className="btn rounded-0 px-3 btn-2 mr-4 d-none d-lg-block"
                    data-toggle="modal"
                    data-target="#smallModal"
                    to=""
                  >
                    SUBSCRIBE
                  </Link>
                </div>
              </div>
              <div className="pos-f-t ">
                <div className="search-icon">
                  <span
                    className="fas fa-search"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  ></span>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div
          className="modal fade"
          id="smallModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered ">
            <div className="container modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Subscribe Now
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Your Email"
                      value={this.setState.email}
                      onChange={this.onChange}
                      name="email"
                    />
                  </div>

                  <button
                    disabled={this.state.btnloading}
                    onClick={this.submitSubscriber.bind(this)}
                    className="btn btn-primary float-right"
                    type="submit"
                    id="sendMessageButton"
                    data-dismiss="modal"
                  >
                    {this.state.btnloading ? "loading..." : " Subscribe"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default header;

{
  /* <div className="container-fluid padding-0">
        <div className="shadow-lg p-3 mb-5 bg-white rounded">
      
          <div className="collapse" id="collapseExample">
            <div className="bg-dark p-4">
              <form className="form-group ">
                <div className="input-group ">
                  <div className="input-group-prepend ">
                    <span className="input-group-text" id="basic-addon1">
                      <span
                        className="fas fa-search"
                        data-toggle=""
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls=""
                      ></span>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control bg-dark text-white"
                    placeholder="search"
                  />
                  <div className="input-group-prepend ">
                    <button
                      type="button"
                      className="close bg-white"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className=" container-fluid">
            <div className="row">
              <div className="col-lg-3 bg-white d-none d-lg-block ">
                <Link
                  to="/"
                  className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <h4 className="m-0 display-7 text-primary text-uppercase">
                    mogulbeat
                  </h4>
                </Link>
              </div>
              <div className="col-lg-9">
                <nav className="navbar navbar-expand-md bg-white navbar-light p-0 ">
                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbarCollapse"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <Link className="navbar-brand d-block d-lg-none" to="/">
                    <h1 className="m-0 display-4 text-primary text-uppercase">
                      mogulbeat
                    </h1>
                  </Link>

                  <div
                    className="collapse navbar-collapse justify-content-between"
                    id="navbarCollapse"
                  >
                    <div className="navbar-nav ff-poppins   py-0">
                      <Link
                        className="nav-item nav-link custom active"
                        to="/"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        Home
                      </Link>

                      <Link
                        className="nav-item nav-link custom"
                        to="/About"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        About
                      </Link>

                      <Link
                        className="nav-item nav-link custom"
                        to="/inspiration"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        Inspiration
                      </Link>

                      <Link
                        className="nav-item nav-link custom"
                        to="/Ceo"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        CEOs
                      </Link>
                      <Link
                        className="nav-item nav-link custom"
                        to="/corporate"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        Corporate
                      </Link>

                      <Link
                        className="nav-item nav-link custom"
                        to="/Warrior"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        Warriors
                      </Link>
                    </div>

                    <Link
                      className="btn rounded-0 px-3 btn-primary mr-3 d-none d-lg-block"
                      data-toggle="modal"
                      data-target="#smallModal"
                      to=""
                    >
                      SUBSCRIBE
                    </Link>
                  </div>
                  <div className="pos-f-t">
                    <div className="search-icon">
                      <span
                        className="fas fa-search"
                        data-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      ></span>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div> 
        </div>

        <div
          className="modal fade"
          id="smallModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered ">
            <div className="container modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Subscribe Now
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Your Email"
                      value={this.setState.email}
                      onChange={this.onChange}
                      name="email"
                    />
                  </div>

                  <button
                    disabled={this.state.btnloading}
                    onClick={this.submitSubscriber.bind(this)}
                    className="btn btn-primary float-right"
                    type="submit"
                    id="sendMessageButton"
                    data-dismiss="modal"
                  >
                    {this.state.btnloading ? "loading..." : " Subscribe"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */
}
