import React, { useEffect, useState } from "react";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogDetail = () => {
  const siteURL = "https://mogulbeat.com/MogulBeatBackend/API/";

  const [blog, set_blog] = useState({});
  const [latestBlogs, set_latestBlogs] = useState([]);
  const [careerBlogs, set_careerBlogs] = useState([]);
  useEffect(() => {
    const getID = async () => {
      const pathName = window.location.pathname;
      const id = pathName.split("/");

      let response = await axios.get(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetBlogById?id=" +
          id[2]
      );
      if (response.data && response.data.data && response.data.data[0]) {
        set_blog(response.data.data[0]);
      }

      response = await axios.get(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogs?isPublished=0"
      );

      if (response.data && response.data.data) {
        const newData = [];
        for (let i in response.data.data) {
          newData.push(response.data.data[i]);
          if (i === "7") break;
        }
        set_latestBlogs(newData);
      }

      response = await axios.get(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogsByCategory?category=Career"
      );

      if (response.data && response.data.data) {
        const newData = [];
        for (let i in response.data.data) {
          newData.push(response.data.data[i]);
          if (i === "5") break;
        }
        set_careerBlogs(newData);
      }
    };

    getID();
  }, []);
  return (
    <div>
      {blog.id ? (
        <Helmet>
          <title>{blog.title}</title>
          <meta name="author" content={blog.author} />
          <meta
            name="description"
            content={blog.description.substring(0, 110) + "..."}
          />
          <link rel="canonical" href="https://www.mogulbeat.com" />
          <meta property="og:title" content={blog.title} />
          <meta
            property="og:image"
            content={siteURL + "/" + blog.featureImage}
          />
          <meta
            property="og:description"
            content={blog.description.substring(0, 110) + "..."}
          />
          <meta
            property="og:url"
            content={`https://www.mogulbeat.com/blogDetail/${blog.id}`}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="https://www.mogulbeat.com" />
          <meta name="twitter:creator" content={blog.author} />
        </Helmet>
      ) : (
        ""
      )}
      <div className="container py-5 ">
        <div key={"nj"} className="row">
          <div
            className="mb-3"
            dangerouslySetInnerHTML={{
              __html: `<style>
                          .public-DraftStyleDefault-block {
                            margin: 0 !important;
                          }
                          .rdw-image-left .rdw-image-imagewrapper{
                            text-align:start !important;
                          }
                          .rdw-image-center .rdw-image-imagewrapper{
                            text-align:center !important;
                          }
                          .rdw-image-right .rdw-image-imagewrapper{
                            text-align:end !important;
                          }
                          figure{
                            margin:0;
                          }
                          .rdw-center-aligned-block > div {text-align: center;}
.myEditor img{
  width:100% !important;
  max-height:350px !important;
}

@media (min-width:556px){
  .myEditor img{ 
    max-height:450px !important;
  }
}
                          </style>`,
            }}
          ></div>

          {/* blog detail */}
          {blog.id ? (
            <div className="col-lg-8">
              <div>
                <h1 className="blog-detail-title">{blog.title}</h1>
                <div style={{ fontFamily: "sofia-pro", fontSize: "14.5px" }}>
                  <b style={{ color: "black" }}> By {blog.author}</b>
                  <span className="mx-2">|</span>
                  {new Date(blog.createdAt).toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </div>

                <div
                  className="myEditor"
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(JSON.parse(blog.blog)),
                  }}
                ></div>

                <div className="text-right">
                  <a
                    title="Share on X"
                    href={`https://twitter.com/intent/tweet?text=${blog.title}&url=https://www.mogulbeat.com/blogDetail/${blog.id}`}
                    className="btn rounded-0 share twitter mr-2"
                  >
                    <i className="fab fa-twitter mr-2" />
                    Tweet
                  </a>
                  <a
                    title="Share on Facebook"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://www.mogulbeat.com/blogDetail/${blog.id}`}
                    className="btn rounded-0 share facebook mr-2"
                  >
                    <i className="fab fa-facebook-f mr-2" />
                    Share
                  </a>
                  <a
                    title="Share on Linkedin"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.mogulbeat.com/blogDetail/${blog.id}`}
                    className="btn rounded-0 share linkedin mr-2"
                  >
                    <i className="fab fa-linkedin mr-2" />
                    Share
                  </a>
                </div>
              </div>
              {/* career blogs */}
              {careerBlogs.length > 0 ? (
                <div>
                  <div className="row mt-5 ">
                    <div className="col-12 mb-1">
                      <div className="d-flex flex-wrap align-items-center">
                        {" "}
                        <h3 className=" ff-sofia-pro">
                          Your Guide To Success
                        </h3>{" "}
                        <a
                          style={{ fontSize: "12px" }}
                          className="text-dark ml-auto font-weight-bold ff-sofia-pro"
                          href="/blog/Career"
                        >
                          <i className="fa fa-angle-double-right pt-1 px-2" />
                          MORE LIKE THIS
                        </a>
                      </div>
                    </div>
                    {careerBlogs.map((v, i) => (
                      <div
                        key={"svsdaf" + i}
                        className="p-2   col-xl-4 col-lg-6"
                      >
                        <a
                          href={"/blogDetail/" + v.id}
                          key={"sd" + i}
                          className="card sm  h-100"
                        >
                          <div>
                            <img
                              style={{ height: "200px", objectFit: "cover" }}
                              className="card-img-top"
                              src={siteURL + "/" + v.featureImage}
                              alt="Card image cap"
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title mb-0">
                              <a href={"/blogDetail/" + v.id}>{v.title}</a>
                            </h5>

                            <p className="mb-1">
                              {" "}
                              <small className="text-muted">
                                {new Date(v.createdAt).toLocaleDateString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </small>
                            </p>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {/* latest blogs */}
          <div className="col-lg-4 py-3">
            <div className="border-left px-3">
              <h4 className="text-center">Latest Articles</h4>
              <div>
                {latestBlogs.map((v, i) => (
                  <div key={i + "cxv"} className="  py-2 rounded ">
                    {/*Card*/}
                    <div className="card hover-effect-1 d-block">
                      {/*Card image*/}
                      <a href={"/blogDetail/" + v.id} className="d-block">
                        <img
                          style={{ height: "200px", objectFit: "cover" }}
                          className="card-img-top"
                          src={siteURL + "/" + v.featureImage}
                          alt="Card image cap"
                        />
                      </a>
                      {/*Card content*/}
                      <div className="card-body">
                        {/*Title*/}{" "}
                        <h5 className="card-title">
                          <a href={"/blogDetail/" + v.id}>{v.title}</a>
                        </h5>
                        {/*Text*/}
                        {/* <p className="card-text">
                                {v.description.substring(0, 110) + "..."}
                              </p> */}
                        <a
                          href={"/blogDetail/" + v.id}
                          className="btn btn-light  btn-sm ff-sofia-pro "
                        >
                          READ MORE
                        </a>
                      </div>
                    </div>
                    {/*/.Card*/}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
