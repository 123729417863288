import React from "react";
class slider2 extends React.Component {
    render() {
      return (
        
<div className="">
<div className="container-fluid py-5 w-75 h-75">
<div className="container rounded py-5 polaroid">
  <div className="row">
   
    <div className="col-lg-7 mt-4 mt-lg-0 text-center ">
      
      <h1 className="mb-4 ">
      The COLLECTION of Luxury Signature
      </h1>
      <p>
      At AUTOGRAPH by H&S, The Signature Collection offers the most opulent Residences and will provide the pinnacle of luxury living. Each property has a prime location, breathtaking private terraces in the sky, and spectacular views. Additionally, several of the Luxury Signature Residences include magnificent private and clean pools. Warm and welcoming interiors provide the ideal location to call home. A new standard of luxury will be established in Lahore by The Signature Collection.</p>
      <a href="" className="btn btn-primary mt-2">
        Learn More
      </a>
    </div>
    <div className="col-lg-5 " >
      <img className="img-fluid rounded border2" src="img/14.jpg" alt="" />

    </div>
  </div>
</div>
</div>


</div>


);
}
}

export default slider2;