import React, { Component, useEffect, useState } from "react";

import "../App";

import Slider from "./slider";
import About from "./about";
//import Services from './services'
import Appointments from "./appointments";
import Features from "./features";
import Actions from "./actions2";
//import Team from './team'
//import Testimonial from './testimonial'
//import Testimonial2 from './testimonial2'
import Testimonial3 from "./testimonial3";
import Slider2 from "./slider2";
import Grab from "./grab";
import Grid from "./grid";
import Blogs from "./blogs";
import Main_Blogs from "./blog_page/main_blog";
import Clients from "./clients2";
import Amenities from "./Amenities/amenities";
import axios from "axios";
const Home = () => {
  const [mainBlog, set_mainBlog] = useState({});
  const [blogs, set_blogs] = useState([]);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogs?isPublished=0"
        )
        .then((res) => {
          const response = res.data;
          if (response.status) {
            //   this.setState({error:{}});

            const newData = [];
            for (let i in response.data) {
              if (i === "0") {
                set_mainBlog(response.data[i]);
              } else {
                newData.push(response.data[i]);
              }

              if (i === "8") break;
            }
            set_blogs(newData);
            // alert(response.message);
          } else {
            // this.setState({ });
            //alert(response.message);
          }
        })
        .then(() => {})
        .catch((error) => {});
    };
    getData();
  }, []);

  return (
    <div>
      <Grid blog={mainBlog} />
      <Main_Blogs blogs={blogs} />
      <Blogs />
      <Actions />
      <Clients />
    </div>
  );
};

export default Home;
