import React, { Component } from "react";
import "../index.js";

import Banner from "./About/abt_header";
import Info from "./Inspiration/ins_info.js";
import ImgGrid from "./Inspiration/img_grid.js";
import Articles from "./Inspiration/articles.js";

import Grid_mult from "./About/grid_mult.js";
import About from "./about";
import Appointments from "./appointments";
import Features from "./features";
import Clients from "./clients2";
import Actions from "./actions2";

const Inspirationpage = () => (
  <div>
    <Info />
    <ImgGrid />
    <Articles />
    <Actions />
  </div>
);

export default Inspirationpage;
