import React, { Component } from "react";

import "../index.js";

import Info from "./warriors/warriors_info.js";

import Warrior_grid from "./warriors/warriors_grid.js";

const Warriorpage = () => (
  <div className="mt-4">
    <Info />
    <Warrior_grid />
  </div>
);

export default Warriorpage;
