import React from "react";
import { Link } from "react-router-dom";

class clients2 extends React.Component {
  render() {
    return (
      <section id="clients" className="section-bg">
        <div className="container-fluid">
          <div className="section-header">
            <h3 className="ff-chronicle">Our CLients</h3>
            <p>Meet our happy clients</p>
          </div>
          <div
            className="row no-gutters clients-wrap clearfix wow fadeInUp"
            style={{ visibility: "visible", animationName: "fadeInUp" }}
          >
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460149/abof.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460224/cropped-cropped-20170720-lucuLogo-squ2-e1500543540803.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460269/104840a62d46c05d285762857fecb61a.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img
                  src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460358/client-4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default clients2;
