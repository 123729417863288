import React, { Component } from "react";
//import './App.css';
import Head from "./components/header4";
import Stickyright from "./components/sticky/stickyright";
import Main from "./components/main";
import Footer from "./components/footer2";

function App() {
  return (
    <div>
      <Head />
      <Main />
      <Stickyright />
      <Footer />
    </div>
  );
}

export default App;
