import React from "react";
import "./threebedcard.css"

//import Gallery from './mansiongallery'


class threebedcard extends React.Component {
  render() {
    return (
 <div className="container rounded py-5 polaroid w-75 h-75">

<br/>

 
  <div className="row">
    <div className="col-sm"><ul className="nav nav-tabs" id="myTab" role="tablist">
    <li className="nav-item">
      <a
        className="nav-link active"
        data-toggle="tab"
        href="#home"
        role="tab"
        aria-controls="home"
      >
        Room Details
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-toggle="tab"
        href="#profile"
        role="tab"
        aria-controls="profile"
      >
        Gallery
      </a>
    </li>
   
  
  
  </ul></div>
    <div className="col-10"><div className="tab-content  ">
    <div className="tab-pane active" id="home" role="tabpanel">
    <h2>Room Details</h2>
  <p>The 3 bedroom Royal suites have been expanded to 2185.20, 2229.60, and 2640 square feet wide luxurious space. A premium experience is promised by the elegant interior. Modern amenities are abundant in the attractively built large kitchen, which makes cooking easy and fun. The awaited luxurious living is complemented by 3 bedrooms and adjoining baths. A tranquil and pleasant view of Lahore’s skyline is promised from the balcony.</p>
      <a href="#" className="btn btn-primary btn-space">
            Book Now
          </a>
          <hr className="solid"></hr>
          <h2>Amenities</h2>
          <div className="row">
    <div className="col"><p>•Attached Bath<br/>
    •Lounge<br/>
    
   
    </p></div>
    <div className="col"> <p>•Balcony<br/>
    •Modern kitchen<br/>
    </p></div>
    <div className="col"><p>•Dining Space<br/>
    •Powder Room</p></div>
    <br/>
    <br/>
    
  </div>
    </div>
    <div className="tab-pane" id="profile" role="tabpanel">
    <div className="row">
   
    <div className=""><h4>From Our Gallery</h4></div>
  </div>
    </div>
    {/* <div className="tab-pane" id="messages" role="tabpanel">
      .3..
    </div>
    <div className="tab-pane" id="settings" role="tabpanel">
      .4..
    </div> */}
  </div></div>
  </div>





  </div>
);
}
}

export default threebedcard;




