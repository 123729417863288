



import React from "react";
import axios from 'axios';
import { clear } from "@testing-library/user-event/dist/clear";
class cont_form extends React.Component {
  
  constructor() {
    super();
    this.state = {
     site_url:'',
     error: {},
     btnloading: false,
     full_name:'',
     email: '',
     website:'',
     comments:''
    }
    this.onChange = this.onChange.bind(this);
  }
  componentWillMount(){
      this.setState({
          //site_url: window['site_url']
      });
  }
  componentDidMount(){

  }


onChange(e){
this.setState({[e.target.name]: e.target.value});
}

submitBiodata2(e){
    e.preventDefault()
   
    this.setState.full_name=""
    this.setState.email=""
    this.setState.website=""
    this.setState.comments=""
    this.setState({btnloading:true});
axios.post('https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/CreateInquiry',{
    full_name : this.state.full_name,
    email : this.state.email,
    website : this.state.website,
    comments : this.state.comments
}).then(res => {const response =res.data;
    if(response.status){
        this.setState({error:{}});
       // alert(response.message);

        this.setState({
          full_name:'',
          email: '',
          website:'',
          comments:''
        });
    }else{
       
        this.setState({error:response.error});
        //alert(response.message);
    }

}).then(() =>{
    this.setState({btnloading:false});
}).catch(error =>{
  
});

}


  render() {
    return (


<div className="container-fluid">
<br/><br/><br/>
  <div className="row">
    
    <div className="col-lg-6">
    <h4>  <span>We are awarded and we are here to serve you!<br/>How can we help you?</span></h4>
          <p><span>If you have any questions about your submission, directory, articles or anything else, we're here to help you!</span></p>
          <div className="position-relative h-100 rounded overflow-hidden">
            <iframe
              style={{ width: "100%", height: "80%", objectFit: "cover" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
              frameBorder={0}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex={0}
            />
          </div>
         
     </div>
    
   
    
    <div className="col-lg-6">
    <br/><br/><br/><br/><br/>
    <div className="contact-form">
            <div id="success" />
            <form name="sentMessage" id="contactForm" noValidate="novalidate">
              <div className="form-row">
                <div className="col-sm-6 control-group">
                    <label>NAME(*)</label>
                  <input
                    type="text"
                    className="form-control p-4"
                    id="name"
                    placeholder="Your Name"
                    required="required"
                    data-validation-required-message="Please enter your name"
                    value={this.setState.full_name} onChange={this.onChange} name="full_name" defaultValue={""}
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="col-sm-6 control-group">
                <label>Email(*)</label>
                  <input
                    type="email"
                    className="form-control p-4"
                    id="email"
                    placeholder="Your Email"
                    required="required"
                    data-validation-required-message="Please enter your email"
                    value={this.setState.email} onChange={this.onChange} name="email" defaultValue={""}
                  />
                  <p className="help-block text-danger" />
                </div>
              </div>
              <div className="control-group">
              <label>Website(*)</label>
                <input
                  type="text"
                  className="form-control p-4"
                  id="website"
                  placeholder="Website"
                  required="required"
                  data-validation-required-message="Please enter a website"
                  value={this.setState.website} onChange={this.onChange} name="website" defaultValue={""}
                />
                <p className="help-block text-danger" />
              </div>
              <div className="control-group">
              <label>Comments(*)</label>
                <textarea
                  className="form-control p-4"
                  rows={6}
                  id="comments"
                  placeholder="Message"
                  required="required"
                  data-validation-required-message="Please enter your comments"
                  value={this.setState.comments} onChange={this.onChange} name="comments" defaultValue={""}
                />
                <p className="help-block text-danger" />
              </div>
              <div>
                
                <button
                disabled={this.state.btnloading}
                onClick={this.submitBiodata2.bind(this)}
                  className="btn btn-primary btn-block"
                  type="submit"
                  id="sendMessageButton"
                >
                  {this.state.btnloading ? 'loading...':' Send Message'}
                 
                </button>
              </div>
            </form>
          </div>
    </div>    

  </div>
  
  </div>
  );
}
}

export default cont_form;