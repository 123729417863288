import React from "react";
import "./fourbedcard.css"

import Gallery from './fourbed_gallery'


class fourbedcard extends React.Component {
  render() {
    return (
 <div className="container rounded py-5 polaroid w-75 h-75">

<br/>

 
  <div className="row">
    <div className="col-sm"><ul className="nav nav-tabs" id="myTab" role="tablist">
    <li className="nav-item">
      <a
        className="nav-link active"
        data-toggle="tab"
        href="#home"
        role="tab"
        aria-controls="home"
      >
        Room Details
      </a>
    </li>
    <li className="nav-item">
      <a
        className="nav-link"
        data-toggle="tab"
        href="#profile"
        role="tab"
        aria-controls="profile"
      >
        Gallery
      </a>
    </li>
   
  
  
  </ul></div>
    <div className="col-10"><div className="tab-content  ">
    <div className="tab-pane active" id="home" role="tabpanel">
    <h2>Room Details</h2>
  <p>The large, two-story, four-bedroom duplex luxury suite has a floor area of around 2846.4 square feet. Both the professional dining area and the aesthetically contemporary living area have direct access to a balcony. Conveniently connected to the dining space and the domestic help section of the duplex is the lovely large kitchen. The four bedrooms, two baths, and en-suite master bedroom complete the luxurious lifestyle that is promised. The balcony promises a relaxed and serene view of Lahore’s skyline.</p>
      <a href="#" className="btn btn-primary btn-space">
            Book Now
          </a>
          <hr className="solid"></hr>
          <h2>Amenities</h2>
          <div className="row">
    <div className="col"><p>•Attached Bath<br/>
    •Maid Room<br/>
    
   
    </p></div>
    <div className="col"> <p>•Balcony<br/>
    •Modern kitchen<br/>
    </p></div>
    <div className="col"><p>•Lounge<br/>
    •Powder Room</p></div>
    <br/>
    <br/>
    
  </div>
    </div>
    <div className="tab-pane" id="profile" role="tabpanel">
    <div className="row">
   
    <div className=""><h4>From Our Gallery</h4><Gallery/></div>
  </div>
    </div>
    {/* <div className="tab-pane" id="messages" role="tabpanel">
      .3..
    </div>
    <div className="tab-pane" id="settings" role="tabpanel">
      .4..
    </div> */}
  </div></div>
  </div>





  </div>
);
}
}

export default fourbedcard;




