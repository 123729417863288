import React, { Component } from "react";

import "../index.js";

import Info from "./Ceos/ceo_info.js";

import Ceo_grid from "./Ceos/ceo_grid.js";

const Ceopage = () => (
  <div className="mt-4">
    <Info />
    <Ceo_grid />
  </div>
);

export default Ceopage;
