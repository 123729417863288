import React from "react";

class ceo_info extends React.Component {
  render() {
    return (
      <div>
        {/* Action Start */}
        <div className="container ">
          <div className="section-header text-center">
            <h1 className="section-heading">CEO's</h1>
            <h4 className="ff-chronicle">About CEOs</h4>
          </div>
          <div className=" rounded2">
            <div className="ceo_bg-action   rounded2" style={{ height: 500 }}>
              <div className="row h-100 align-items-center justify-content-center">
                <div className="col-11 text-left">
                  <h3 className="text-white mb-4 ff-chronicle">Elon Musk</h3>
                  <p className="text-white mb-4  ff-poppins">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores
                  </p>

                  <a
                    className="btn ff-chronicle btn-primary py-3 px-5 mt-2"
                    href="#"
                  >
                    Read Articles
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Action End */}
      </div>
    );
  }
}

export default ceo_info;
