import React from "react";

class co_grid extends React.Component {
  render() {
    return (
      <div className="container  my-4 bg-light">
        <br />
        <br />
        <br />
        <div className="row ">
          <div className="col-lg-6">
            <br />
            <br />
            <br />
            <h2 className="ff-chronicle">APPLE</h2>
            <hr className="solid"></hr>
            <br />
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et
            </p>{" "}
            <a className="text-dark font-weight-bold" href="">
              <i className="fa fa-angle-double-right pt-1 px-3" />
              Read more
            </a>
          </div>

          <div className="col-lg-6">
            <img
              className="img-fluid rounded"
              src="img/Mask_Group_29.png"
              alt=""
            />
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default co_grid;
