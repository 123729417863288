import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./Home";
import About from "./Abouts";
import Inspiration from "./Inspiration";
import Ceos from "./Ceo";
import Corporate from "./Corporate";
import Blogs from "./blog";
import Warrior from "./Warrior";
import BlogDetail from "./blogDetails";

import { Link } from "react-router-dom";
import Fourbed from "./res_fourbed";
import Threebed from "./res_threebed";
import Projects from "./projects";
import Proj1_details from "./Proj1_details";
import Contactus from "./Contactus";

class Main extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/About" component={About} />
          <Route path="/inspiration" component={Inspiration} />
          <Route path="/ceo" component={Ceos} />
          <Route path="/corporate" component={Corporate} />
          <Route path="/blog" component={Blogs} />
          <Route path="/blog/:slug" component={Blogs} />
          <Route path="/contactus" component={Contactus} />
          <Route path="/Warrior" component={Warrior} />
          <Route path="/blogDetail/:id" component={BlogDetail} />
        </Switch>
      </div>
    );
  }
}

export default Main;
