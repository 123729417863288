import axios from "axios";
import React from "react";
//import logo from '../img/logo-2.png'
import { Link } from "react-router-dom";
function refreshPage() {
  window.location.reload();
}

class blogs extends React.Component {
  constructor() {
    super();
    this.state = {
      siteURL: "https://mogulbeat.com/MogulBeatBackend/API/",
      error: {},

      blogs: [],
    };
  }

  componentWillMount() {
    axios
      .get(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogs?isPublished=0"
      )
      .then((res) => {
        const response = res.data;
        if (response.status) {
          //   this.setState({error:{}});
          const newData = [];
          for (let i in response.data) {
            newData.push(response.data[i]);
            console.log(i);
            if (i === "7") break;
          }
          this.setState({ blogs: newData });
          // alert(response.message);
        } else {
          this.setState({ error: response.error });
          //alert(response.message);
        }
      })
      .then(() => {})
      .catch((error) => {});
  }

  render() {
    return (
      <div className="container  mt-5">
        <div className="section-header text-center">
          <h1 className="section-heading">More Articles</h1>
        </div>
        <div className="row">
          {this.state.blogs.map((v, i) => (
            <div key={i + "cxv"} className="col-md-4 py-3 rounded ">
              {/*Card*/}
              <div className="card ">
                {/*Card image*/}
                <img
                  style={{ height: "300px", objectFit: "contain" }}
                  className="card-img-top"
                  src={this.state.siteURL + "/" + v.featureImage}
                  alt="Card image cap"
                />
                {/*Card content*/}
                <div className="card-body">
                  {/*Title*/}
                  <h4 className="card-title">{v.title}</h4>
                  {/*Text*/}
                  <p className="card-text">
                    {v.description.substring(0, 110) + "..."}
                  </p>
                  <Link
                    to={"/blogDetail/" + v.id}
                    className="btn btn-primary ff-chronicle"
                    href=""
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
              {/*/.Card*/}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default blogs;
