import React from "react";

const Grid = ({ blog }) => {
  const siteURL = "https://mogulbeat.com/MogulBeatBackend/API/";
  return blog.title ? (
    <section style={{ padding: "80px 0" }}>
      <div className="container">
        <div className="row">
          <div className="col" style={{ color: "var(--text-color-2)" }}>
            <h2
              className="section-heading"
              style={{ color: "#565656", lineHeight: ".9" }}
            >
              <a
                href={"/blogDetail/" + blog.id}
                className="read-more text-decoration-none cursor-pointer"
                style={{ fontSize: ".7em" }}
              >
                {blog.title}
              </a>
            </h2>
            <br />
            <div className="ff-sofia-pro">
              <span>
                {new Date(blog.createdAt).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </span>
              <p>{blog.description}</p>{" "}
              <a
                style={{ fontSize: "16px", color: "#000" }}
                href={"/blogDetail/" + blog.id}
                className="read-more ff-sofia-pro text-decoration-none"
              >
                Read more
              </a>
            </div>
          </div>

          <div className="col-lg-8">
            <div className="carousel-item active">
              <img
                style={{ objectFit: "cover" }}
                src={siteURL + "/" + blog.featureImage}
                className="d-block w-100"
                alt={blog.title}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    ""
  );
};

export default Grid;
