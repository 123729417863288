import React, { Component } from "react";

import "../index.js";

//import Banner from './Media/blogs/blogs_header'
//import Blog from './Media/blogs/blogs'
import Blogs from "./blog_page/blogs1.js";

const Blogpage = () => (
  <div>
    <Blogs />
  </div>
);

export default Blogpage;
