import axios from "axios";
import React, { useEffect, useState } from "react";
//import logo from '../img/logo-2.png'
import banner from "../img/success-banner.avif";
import { Link } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import { useRef } from "react";
function refreshPage() {
  window.location.reload();
}

const Blogs = () => {
  const [message, set_message] = useState("");
  const [submitting, set_submitting] = useState(false);
  const emailInput = useRef();
  const siteURL = "https://mogulbeat.com/MogulBeatBackend/API/";
  const [blogs, set_blogs] = useState([]);

  const owlCarousel1 = useRef();

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/GetAllBlogsByCategory?category=Career"
      );
      const datad = [];
      if (response.data) {
        const { data } = response.data;
        if (data) {
          for (let i in data) {
            datad.push(data[i]);
            // if (i === "3") break;
          }

          set_blogs(datad);
        }
      }
    };

    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      set_message("");
    }, 3000);
  }, [message]);

  function submitSubscriber(e) {
    e.preventDefault();
    if (submitting) return;
    set_submitting(true);

    const email = emailInput.current.value;

    axios
      .post(
        "https://mogulbeat.com/MogulBeatBackend/API/mogulbeat/CreateSubscriber",
        { email }
      )
      .then((res) => {
        const response = res.data;
        if (response.status) {
          emailInput.current.value = "";
          if (response.message) {
            set_message(response.message);
          }
        } else {
          console.log(response.error);
        }
      })
      .then(() => {
        set_submitting(false);
      })
      .catch((error) => {});
  }

  return (
    <section id="from-blog" className="pt-0">
      <div className="container-fluid" style={{ maxWidth: "1300px" }}>
        <div className="row">
          <div className="pb-4 mb-5 col-12 text-center">
            <div
              style={{ borderTop: "4px solid #c1c8cc", fontWeight: "400" }}
              className="pt-5 ff-poppins"
            >
              <div
                style={{
                  color: "#206494",
                  fontSize: "25px",
                  marginBottom: "25px",
                }}
              >
                Every week we'll send you blogging advice, tips and in-depth
                tutorials.
              </div>
              <div
                style={{
                  color: "#00365f",
                  fontSize: "32px",
                  marginBottom: "30px",
                }}
              >
                JOIN OUR COMMUNITY OF{" "}
                <span style={{ color: "#ff671f" }}>300,000+</span> BLOGGERS!
              </div>
              <form
                onSubmit={submitSubscriber}
                className="mx-auto"
                style={{ maxWidth: "524px" }}
              >
                {message ? (
                  <div
                    style={{
                      color: "#206494",
                      marginBottom: "25px",
                      background: " #e6ecf0",
                      padding: "14px",
                      textAlign: "start",
                      borderRadius: "3px",
                    }}
                  >
                    {message}
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex align-items-center">
                  <div className="flex-fill px-1 mr-3">
                    <input
                      required
                      maxLength={100}
                      type="email"
                      ref={emailInput}
                      placeholder="Email"
                      style={{ border: "1px solid #206494" }}
                      className="form-control rounded-0 py-4 "
                    />
                  </div>
                  <div>
                    <button className="btn  px-5 btn-2">
                      {submitting ? "Submitting..." : "SUBSCRIBE"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-12">
            <div className="from-blog-area">
              <div className="title-area">
                <h2 className="tittle text-center section-heading">
                  Your Guide To Success
                </h2>
                <span className="tittle-line" />
                <p />
              </div>
              {/* From Blog content */}
              <div className="from-blog-content mt-4 pt-1">
                {blogs.length > 0 ? (
                  <div
                    className="position-relative mx-auto"
                    style={{ width: "90%" }}
                  >
                    <div
                      className="w-100"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        zIndex: "130",
                      }}
                    >
                      <span
                        onClick={() => {
                          console.log(owlCarousel1.current.prev());
                        }}
                        className="owl-left-btn"
                      >
                        <i className="fa fa-angle-left"></i>
                      </span>
                      <span
                        onClick={() => {
                          console.log(owlCarousel1.current.next());
                        }}
                        className="owl-right-btn"
                      >
                        <i className="fa fa-angle-right"></i>
                      </span>
                    </div>

                    <OwlCarousel
                      responsive={{
                        0: { items: 1 },
                        480: { items: 1 },
                        768: { items: 2 },
                        992: { items: 3 },
                      }}
                      ref={owlCarousel1}
                      className="owl-theme"
                      margin={10}
                      dots={false}
                      loop
                    >
                      {blogs.map((v, i) => (
                        <div
                          key={"svsdaf" + i + new Date().toTimeString()}
                          className="item"
                        >
                          <div key={"sd" + i} className="card h-100">
                            <div>
                              <img
                                style={{ height: "200px", objectFit: "cover" }}
                                className="card-img-top"
                                src={siteURL + "/" + v.featureImage}
                                alt="Card image cap"
                              />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title">
                                <a href={"/blogDetail/" + v.id}>{v.title}</a>
                              </h5>
                              <p className="card-text">
                                {v.description.substring(0, 110) + "..."}
                              </p>
                              <p className="card-text">
                                {/* <small className="text-muted">
                          {new Date(v.createdAt).toLocaleDateString(
                            "en-us",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </small> */}
                                <br />
                                <a
                                  href={"/blogDetail/" + v.id}
                                  className="btn btn-light ff-sofia-pro btn-sm"
                                >
                                  READ MORE
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-6 col-lg-2 ml-auto d-flex align-items-center mt-4 position-relative">
                  <a
                    className="text-dark font-weight-bold "
                    href="/blog/Career"
                  >
                    <i className="fa fa-angle-double-right pt-1 px-3" />
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
