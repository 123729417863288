import React, { Component } from "react";

import "../index.js";

//import Banner from './Media/blogs/blogs_header'
//import Blog from './Media/blogs/blogs'
import Blog_detail from "./blog_page/blogDetail.js";

const BlogDetail = () => (
  <div>
    <Blog_detail />
  </div>
);

export default BlogDetail;
